import React, { useState, useEffect } from 'react';
import classes from './HomeScreen.module.css';
import images from '../../imageMapping';

function HomeScreen(props) {
    const { setHomeScreen, setTutorial, levelNumber, setAboutScreen } = props;
    const [randomImageIndices, setRandomImageIndices] = useState([1, 2, 3, 4]); // Initialize with a fixed set of indices

    const generateRandomImages = () => {
        const newIndices = [];
        while (newIndices.length < 4) {
            const newIndex = Math.floor(Math.random() * 20) + 1;
            if (!newIndices.includes(newIndex)) {
                newIndices.push(newIndex);
            }
        }
        setRandomImageIndices(newIndices);
        setTimeout(generateRandomImages, 2000); // Set the timeout to call itself again after 2 seconds
    };

    useEffect(() => {
        generateRandomImages(); // Start the recursion when component mounts

        return () => {
            // Clean up if necessary
        };
    }, []);

    const openMenu = () => {
        return () => { // Wrapping in another arrow function
            setHomeScreen(false);
        };
    };

    const openTutorial = () => {
        return () => { // Wrapping in another arrow function
            setTutorial(true);
            setHomeScreen(false);
        };
    };

    const openAbout = () => {
        return () => { // Wrapping in another arrow function
            setAboutScreen(true);
            setHomeScreen(false);
        };
    };

    return (
        <div className={classes.Container}>
            <div className={classes.ImageIconContainer}>
                {randomImageIndices.map((index, i) => (
                    <img key={index} src={images[index]} alt={`Image ${index}`} />
                ))}
            </div>
            <h1 onClick={openMenu()}>play</h1>
            {levelNumber > 10 ? <h1>free ranger</h1> : null}
            <a href="https://buymeacoffee.com/thomasgodley" target="_blank" rel="noopener noreferrer"><h1 className={classes.aboutButton}>support</h1></a>
        </div>
    );
}

export default HomeScreen;
