import React from 'react';
import classes from './Menu.module.css';
import images from '../../imageMapping';

function Main(props) {
    const { levelNumber, setLevelComplete, setSideNumber, startTimer, setHomeScreen, setShopScreen } = props;

    const startLevel = (sideNumber) => () => {
        setSideNumber(sideNumber);
        setLevelComplete(false);
        startTimer();
    };

    const goHome = () => () => {
        setHomeScreen(true);
    };

    const goShop = () => {
        setShopScreen(true);
    };

    const renderArea = (areaIndex, areaName, sideNumber) => (
        <div className={classes.LevelContainer} key={areaIndex}>
            {levelNumber > areaIndex - 1 ? (
                <div className={classes.LevelContainerInner} onClick={startLevel(sideNumber)}>
                    <h1>{areaName}</h1>
                    <h1>{sideNumber}x{sideNumber}</h1>
                </div>
            ) : (
                <div className={classes.LevelContainerInnerLocked}>
                    <h1 className={classes.Locked}>{areaName}</h1>
                    <h1 className={classes.Locked}>{sideNumber}x{sideNumber}</h1>
                </div>
            )}
        </div>
    );

    return (
        <div className={classes.Container}>
            {renderArea(1, 'tinywoods', 4)}
            {renderArea(2, 'smallhills', 5)}
            {renderArea(3, 'mediacres', 6)}
            {renderArea(4, 'boulderun', 7)}
            {renderArea(5, 'thousand trees', 8)}
            <h1 className={classes.BackHomeButton} onClick={goHome()}>back to home</h1>
            <h1 className={classes.ShopButton} onClick={goShop}>upgrades shop</h1>
        </div>
    );
}

export default Main;
