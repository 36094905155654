import './App.css';
import Main from './Main';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className="App">
      <Main/>
      <Analytics/>
    </div>
  );
}

export default App;
