import fullyGrown from './Symbols/FullyGrown.svg';
import fullyGrownB from './Symbols/FullyGrownB.svg';
import fullyGrownC from './Symbols/FullyGrownC.svg';
import fullyGrownD from './Symbols/FullyGrownD.svg';
import midGrown from './Symbols/MidGrown.svg';
import midGrownYoung from './Symbols/MidGrownYoung.svg';
import sapling from './Symbols/Sapling.svg';
import seed from './Symbols/Seed.svg';
import saplingYoung from './Symbols/SaplingYoung.svg';
import seedGerm from './Symbols/SeedGerm.svg';
import dead from './Symbols/Dead.svg';
import deadAged from './Symbols/DeadAged.svg';
import rock from './Symbols/Rock.svg';
import fire from './Symbols/Fire.svg';
import smoke from './Symbols/Smoke.svg';
import lookOut from './Symbols/LookOut.svg';
import lookOutInverted from './Symbols/LookOutInverted.svg';
import seedGolden from './Symbols/SeedGolden.svg';
import seedLightGolden from './Symbols/SeedLightGolden.svg';
import water from './Symbols/Water.svg';
import rain from './Symbols/Rain.svg';

const images = {
  1: deadAged,
  2: dead,
  3: fullyGrownD,
  4: fullyGrownD,
  5: fullyGrownC,
  6: fullyGrownC,
  7: fullyGrownB,
  8: fullyGrownB,
  9: fullyGrown,
  10: fullyGrown,
  11: fullyGrown,
  12: fullyGrown,
  13: fullyGrown,
  14: midGrown,
  15: midGrown,
  16: midGrownYoung,
  17: sapling,
  18: saplingYoung,
  19: seedGerm,
  20: seed,
  21: lookOut,
  '-1': rock,
  '-2': fire,
  '-3': smoke,
  40: seedGolden,
  41: seedLightGolden,
  42: lookOutInverted,
  43: rain,
  44: water,
};

export default images;
