import React, { useEffect, useState } from 'react';
import classes from './Tutorial.module.css';
import images from '../../imageMapping';

function Tutorial(props) {
    const [tutorialStage, setTutorialStage] = useState(1);
    const { setTutorial, setHomeScreen } = props;

    const nextStage = () => {
        return () => { // Wrapping in another arrow function
            if(tutorialStage === 8){
                setTutorial(false);
                setHomeScreen(true);
            }else{
                setTutorialStage(tutorialStage + 1);
                console.log(tutorialStage);
            }
        };
    };

    return (
        <div className={classes.Container}>
            {tutorialStage === 1 ? <Growing nextStage={nextStage}/> : null}
            {tutorialStage === 2 ? <Dropping nextStage={nextStage}/> : null}
            {tutorialStage === 3 ? <Fire nextStage={nextStage}/> : null}
            {tutorialStage === 4 ? <FireFighting nextStage={nextStage}/> : null}
        </div>
    );
}

export default Tutorial;

function Planting(props) {
    const { nextStage } = props;
    const [cellClicked, setCellClicked] = useState(false);

    const handleCellClick = () => {
        setCellClicked(true);
    };

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCell} onClick={handleCellClick}>
                    {cellClicked && <img src={images[20]} alt='seed'/>}
                </div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
            </div>
            <h1>tap to plant seeds</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function Growing(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(14);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === 14 ? 13 : 14);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[treeType]} alt='tree'/></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
            </div>
            <h1>trees grow over time</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function Dropping(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(5);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === 5 ? 4 : 5);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.Cell}>{treeType === 4 ? <><img key={treeType} src={images[20]} alt='seed'/></> : null}</div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[treeType]} alt='tree'/></div>
                <div className={classes.Cell}>{treeType === 4 ? <><img key={treeType} src={images[19]} alt='seed'/></> : null}</div>
                <div className={classes.Cell}>{treeType === 4 ? <><img key={treeType} src={images[19]} alt='seed'/></> : null}</div>
                <div className={classes.Cell}>{treeType === 4 ? <><img key={treeType} src={images[20]} alt='seed'/></> : null}</div>
                <div className={classes.Cell}></div>
            </div>
            <h1>trees can drop seeds</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function Banking(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(1);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === 1 ? 2 : 1);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.SelectedCellTree}>{treeType === 1 ? <><img key={treeType} src={images[17]} alt='seed'/></> : <><img key={treeType} src={images[18]} alt='seed'/></>}</div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[treeType]} alt='tree'/></div>
                <div className={classes.SelectedCell}>{treeType === 2 ? <><img key={treeType} src={images[20]} alt='seed'/></> : null}</div>
                <div className={classes.SelectedCellTree}>{treeType === 1 ? <><img key={treeType} src={images[19]} alt='seed'/></> : <><img key={treeType} src={images[20]} alt='seed'/></>}</div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
            </div>
            <h1>some get automatically harvested</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function Fire(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(14);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === 14 ? -2 : 14);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[16]} alt='seed'/></> : <><img key={treeType} src={images[17]} alt='seed'/></>}</div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[treeType]} alt='tree'/></div>
                <div className={classes.Cell}></div>
                <div className={classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[-2]} alt='seed'/></> : <><img key={treeType} src={images[-2]} alt='seed'/></>}</div>
                <div className={classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[-2]} alt='seed'/></> : <><img key={treeType} src={images[17]} alt='seed'/></>}</div>
                <div className={classes.Cell}></div>
            </div>
            <h1>look out for fire</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function LookOuts(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(-1);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === -1 ? 21 : -1);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[treeType]} alt='tree'/></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
                <div className={treeType === 21 ? classes.lookOutCell : classes.Cell}></div>
            </div>
            <h1>lookouts cost 5 seeds<br/>build them on hills</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function FireFighting(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(-2);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === 14 ? -2 : 14);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.lookOutCell}>{treeType === -2 ? <><img key={treeType} src={images[16]} alt='seed'/></> : <><img key={treeType} src={images[17]} alt='seed'/></>}</div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[21]} alt='tower'/></div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.lookOutCell}>{treeType === -2 ? <><img key={treeType} src={images[-2]} alt='seed'/></> : <><img key={treeType} src={images[-3]} alt='seed'/></>}</div>
                <div className={classes.lookOutCell}>{treeType === -2 ? <><img key={treeType} src={images[16]} alt='seed'/></> : <><img key={treeType} src={images[17]} alt='seed'/></>}</div>
                <div className={classes.lookOutCell}></div>
            </div>
            <h1>fire in range can be<br/>extinguished by tapping</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}

function Winning(props){
    const { nextStage } = props;
    const [treeType, setTreeType] = useState(-2);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTreeType(prevTreeType => prevTreeType === -2 ? 14 : -2);
        }, 2000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(timeoutId);
    }, [treeType]);

    return (
        <div className={classes.TutorialContainers}>
            <div className={classes.Grid}>
                <div className={classes.SelectedCellTree}><img key={treeType} src={images[21]} alt='tower'/></div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.Cell}></div>
                <div className={classes.lookOutCell}></div>
                <div className={classes.lookOutCell}>{treeType === -2 ? <><img key={treeType} src={images[17]} alt='seed'/></> : <><img key={treeType} src={images[16]} alt='seed'/></>}</div>
                <div className={treeType === 14 ? classes.lookOutCell : classes.Cell}></div>
                <div className={classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[7]} alt='seed'/></> : <><img key={treeType} src={images[6]} alt='seed'/></>}</div>
                <div className={treeType === 14 ? classes.lookOutCell : classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[17]} alt='seed'/></> : <><img key={treeType} src={images[16]} alt='seed'/></>}</div>
                <div className={treeType === 14 ? classes.SelectedCellTree : classes.SelectedCellTree}>{treeType === -2 ? <><img key={treeType} src={images[-1]} alt='seed'/></> : <><img key={treeType} src={images[21]} alt='seed'/></>}</div>
            </div>
            <h1>make an area safe by covering every hill</h1>
            <h1 className={classes.NextButton} onClick={nextStage()}>next</h1>
        </div>
    );
}