import React, { useState, useEffect } from 'react';
import classes from './Main.module.css';
import HomeScreen from './Modules/HomeScreen/HomeScreen';
import LevelCleared from './Modules/LevelCleared/LevelCleared'
import Menu from './Modules/Menu/Menu'
import Grid from './Modules/Grids/Grid';
import images from './imageMapping';
import Tutorial from './Modules/Tutorial/Tutorial';
import About from './Modules/About/About';
import Shop from './Modules/Shop/Shop';

function Main() {
    const [tutorial, setTutorial] = useState(false);
    const [aboutScreen, setAboutScreen] = useState(false);
    const [homeScreen, setHomeScreen] = useState(true);
    const [shopScreen, setShopScreen] = useState(false);
    const [towerPlaced, setTowerPlaced] = useState(false);
    const [barFill, setBarFill] = useState(2);
    const [fireActive, setFireActive] = useState(false);
    const [autoSeeder, setAutoSeeder] = useState(() => {
        const savedAutoSeeder = localStorage.getItem('savedAutoSeeder');
        return savedAutoSeeder !== null ? savedAutoSeeder === "true" : false;
    });
    const [fireService, setFireService] = useState(() => {
        const savedFireService = localStorage.getItem('savedFireService');
        return savedFireService !== null ? savedFireService === "true" : false;
    });
    const [maxWaterStorage, setMaxWaterStorage] = useState(() => {
        const savedWaterStorage = localStorage.getItem('waterStorage');
        return savedWaterStorage ? parseInt(savedWaterStorage, 10) : 2;
    });
    const [range, setRange] = useState(() => {
        const savedRange = localStorage.getItem('range');
        return savedRange ? parseInt(savedRange, 10) : 1;
    });
    const [money, setMoney] = useState(() => {
        const savedMoney = localStorage.getItem('money');
        return savedMoney ? parseInt(savedMoney, 10) : 0;
    });

    const [score, setScore] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [levelComplete, setLevelComplete] = useState(true);
    const [highLevelNumber, setHighLevelNumber] = useState(0);
    const [levelClearedScreen, setLevelClearedScreen] = useState(false);
    const [levelFailed, setLevelFailed] = useState(false);
    const [sideNumber, setSideNumber] = useState(0);
    const [numberOfRocks, setNumberOfRocks] = useState(0);
    const [levelTime, setLevelTime] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [highLevelRating, setHighLevelRating] = useState([0,0,0,0,0,0]);
    const [savedHighRating, setSavedHighRating] = useState(() => {
        const savedRating = localStorage.getItem('highLevelRating');
        return savedRating ? JSON.parse(savedRating) : highLevelRating; // Initialize to highLevelRating shape or undefined
    });
    
    const [levelNumber, setlevelNumber] = useState(() => {
        const savedLevelNumber = localStorage.getItem('levelNumber');
        return savedLevelNumber ? parseInt(savedLevelNumber, 10) : 1;
    });
    

    useEffect(() => {
        const savedLevelNumber = localStorage.getItem('levelNumber');
        const savedLevelRating = localStorage.getItem('highLevelRating');
        const savedMoney = localStorage.getItem('money');
        const savedWaterStorage = localStorage.getItem('waterStorage');
        const savedRange = localStorage.getItem('range');
        const savedAutoSeeder = localStorage.getItem('savedAutoSeeder');
        const savedFireService = localStorage.getItem('savedFireService');
        if (savedLevelNumber) {
            setlevelNumber(parseInt(savedLevelNumber, 10));
        }
        if (savedLevelRating) {
            const temporaryRatingArray = JSON.parse(savedLevelRating);
            setHighLevelRating(temporaryRatingArray);
        }
        if (savedMoney) {
            setMoney(parseInt(savedMoney, 10));
        }
        if (savedWaterStorage) {
            setMaxWaterStorage(parseInt(savedWaterStorage, 10));
        }
        if (savedRange) {
            setRange(parseInt(savedRange, 10));
        }
        if (savedAutoSeeder !== null) { // Check if the value exists in localStorage
            // Parse the stored value to a boolean
            const isAutoSeederEnabled = savedAutoSeeder === "true";
            setAutoSeeder(isAutoSeederEnabled);
        }
        if (savedFireService !== null) { // Check if the value exists in localStorage
            // Parse the stored value to a boolean
            const isFireServiceEnabled = savedFireService === "true";
            setFireService(isFireServiceEnabled); // Set the fireService state
        }        
    }, []);
    
    useEffect(() => {
        if(levelNumber > highLevelNumber) {
            setHighLevelNumber(levelNumber);
            localStorage.setItem('levelNumber', levelNumber.toString());
        }
    }, [levelNumber, highLevelNumber]);

    useEffect(() => {
        localStorage.setItem('money', money.toString());
    }, [money]); 
    
    useEffect(() => {
        localStorage.setItem('waterStorage', maxWaterStorage.toString());
    }, [maxWaterStorage]);  
    
    useEffect(() => {
        localStorage.setItem('range', range.toString());
    }, [range]);

    useEffect(() => {
        localStorage.setItem('savedAutoSeeder', autoSeeder.toString());
    }, [autoSeeder]);
    
    useEffect(() => {
        localStorage.setItem('savedFireService', fireService.toString());
    }, [fireService]);

    useEffect(() => {
        for (let i = 0; i < highLevelRating.length; i++) {
            if (highLevelRating[i] > savedHighRating[i]) {
                setSavedHighRating(prevSavedHighRating => {
                    const newSavedHighRating = [...prevSavedHighRating]; // Copy the previous state
                    newSavedHighRating[i] = highLevelRating[i]; // Update the specific value
                    localStorage.setItem('highLevelRating', JSON.stringify(newSavedHighRating)); // Save highLevelRating to localStorage
                    return newSavedHighRating; // Return the updated state
                });
                break; // Exit loop if any value is updated
            }
        }
    }, [savedHighRating, highLevelRating]);
    

    const startTimer = () => {
        const id = setInterval(() => {
            setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
        }, 1000);
        setIntervalId(id); // Store interval ID in state
        setLevelTime(0); // Reset levelTime when timer starts
    };

    const stopTimer = () => {
        clearInterval(intervalId); // Clear interval using stored ID
        setLevelTime(elapsedTime); // Store the elapsed time when timer is stopped
    };

    useEffect(() => {
        setNumberOfRocks(Math.ceil((sideNumber*sideNumber) / 9));
    }, [sideNumber]);

    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return (
        <div className={classes.Container}>
            <div className={fireActive ? classes.MainFire : classes.Main}>
                <div className={classes.TitleArea}>
                    <h1>little lookouts</h1>
                </div>

                {shopScreen ? 
                <Shop 
                setShopScreen={setShopScreen}
                maxWaterStorage={maxWaterStorage}
                setMaxWaterStorage={setMaxWaterStorage}
                range={range}
                setRange={setRange}
                money={money}
                setMoney={setMoney}
                autoSeeder={autoSeeder}
                setAutoSeeder={setAutoSeeder}
                fireService={fireService}
                setFireService={setFireService}
                /> : null}

                {homeScreen ? 
                <HomeScreen
                setHomeScreen={setHomeScreen}
                setTutorial={setTutorial}
                levelNumber={levelNumber}
                setAboutScreen={setAboutScreen}
                /> : null}

                {aboutScreen ? <About setAboutScreen={setAboutScreen} setHomeScreen={setHomeScreen}/> : null}

                {!homeScreen && tutorial ? <Tutorial setTutorial={setTutorial} setHomeScreen={setHomeScreen} /> : null}

                {!homeScreen && levelComplete && levelClearedScreen && !tutorial ?
                <LevelCleared
                levelTime={levelTime}
                setLevelClearedScreen={setLevelClearedScreen}
                levelFailed={levelFailed}
                setLevelFailed={setLevelFailed}
                levelNumber={levelNumber}
                highLevelRating={highLevelRating}
                setHighLevelRating={setHighLevelRating}
                sideNumber={sideNumber}
                startTimer={startTimer}
                setLevelComplete={setLevelComplete}
                setBarFill={setBarFill}
                setTowerPlaced={setTowerPlaced}
                money={money}
                setShopScreen={setShopScreen}
                /> : null}

                {!shopScreen && !homeScreen && !aboutScreen  && levelComplete && !levelClearedScreen && !tutorial ?
                <Menu
                levelNumber={levelNumber}
                setLevelComplete={setLevelComplete}
                setSideNumber={setSideNumber}
                startTimer={startTimer}
                setHomeScreen={setHomeScreen}
                savedHighRating={savedHighRating}
                setShopScreen={setShopScreen}
                /> : null}

                {!homeScreen && !aboutScreen   && !levelComplete && !levelClearedScreen && !tutorial ?
                <Grid
                sideLength={sideNumber}
                numberOfRocks={numberOfRocks}
                score={score}
                setScore={setScore}
                setLevelComplete={setLevelComplete}
                setlevelNumber={setlevelNumber}
                levelNumber={levelNumber}
                setLevelClearedScreen={setLevelClearedScreen}
                setLevelFailed={setLevelFailed}
                stopTimer={stopTimer}
                elapsedTime={elapsedTime}
                setElapsedTime={setElapsedTime}
                towerPlaced={towerPlaced}
                setTowerPlaced={setTowerPlaced}
                barFill={barFill}
                setBarFill={setBarFill}
                fireActive={fireActive}
                setFireActive={setFireActive}

                maxWaterStorage={maxWaterStorage}
                range={range}
                money={money}
                setMoney={setMoney}
                autoSeeder={autoSeeder}
                fireService={fireService}
                /> : null}

            </div>
        </div>
    );
}

export default Main;
