import React from 'react';
import classes from './Shop.module.css';
import images from '../../imageMapping';

function Shop(props) {
    const { levelNumber, setShopScreen, maxWaterStorage, setMaxWaterStorage, money, setMoney, range, setRange, autoSeeder, setAutoSeeder, fireService, setFireService } = props;

    const goMenu = () => () => {
        setShopScreen(false);
    };

    const purchaseWater = () => {

        if(maxWaterStorage === 2 && money > 499){
            setMoney(money-500);
            setMaxWaterStorage(maxWaterStorage+2);
        }
        if(maxWaterStorage === 4 && money > 749){
            setMoney(money-750);
            setMaxWaterStorage(maxWaterStorage+2);
        }
        if(maxWaterStorage === 6 && money > 1499){
            setMoney(money-1500);
            setMaxWaterStorage(maxWaterStorage+2);
        }
    };

    const purchaseRange = () => {

        if(range === 1 && money > 999){
            setMoney(money-1000);
            setRange(range+1);
        }
        if(range === 2 && money > 3499){
            setMoney(money-3500);
            setRange(range+1);
        }
        if(range === 3 && money > 11999){
            setMoney(money-12000);
            setRange(range+1);
        }
    };

    const purchaseAutoSeeder = () => {
        if(!autoSeeder && money > 3999){
            setMoney(money-4000);
            setAutoSeeder(true);
        }
    };

    const purchaseFireService = () => {
        if(!fireService && money > 5999){
            setMoney(money-6000);
            setFireService(true);
        }
    };

    return (
        <div className={classes.Container}>
            <h1 className={classes.Title}>upgrades shop</h1>
            <h1 className={classes.Savings}>savings: ${money}</h1>

            <div className={classes.ShopItem} onClick={purchaseWater}>
                <div className={classes.UpgradeLevel}><img src={images[43]}/></div>
                {maxWaterStorage === 2 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>water tank 1</h1>
                    <h1 className={classes.UpgradeCost}>cost: $500</h1>
                </div> 
                : maxWaterStorage === 4 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>water tank 2</h1>
                    <h1 className={classes.UpgradeCost}>cost: $750</h1>
                </div> 
                : maxWaterStorage === 6 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>water tank 3</h1>
                    <h1 className={classes.UpgradeCost}>cost: $1500</h1>
                </div> 
                : 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>water tank max size</h1>
                </div> 
                }
            </div>

            <div className={classes.ShopItem} onClick={purchaseRange}>
                <div className={classes.UpgradeLevel}><img src={images[21]}/></div>
                {range === 1 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>tower range 1</h1>
                    <h1 className={classes.UpgradeCost}>cost: $1000</h1>
                </div>
                : range === 2 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>tower range 2</h1>
                    <h1 className={classes.UpgradeCost}>cost: $3500</h1>
                </div>
                : range === 3 ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>tower range 3</h1>
                    <h1 className={classes.UpgradeCost}>cost: $12000</h1>
                </div>
                :
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>tower range max</h1>
                </div> 
                }
            </div>

            <div className={classes.ShopItem} onClick={purchaseAutoSeeder}>
                <div className={classes.UpgradeLevel}><img src={images[19]}/></div>
                {!autoSeeder ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>auto seeder</h1>
                    <h1 className={classes.UpgradeCost}>cost: $4000</h1>
                </div>
                :
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>auto seeder on</h1>
                </div> 
                }
            </div>

            <div className={classes.ShopItem} onClick={purchaseFireService}>
                <div className={classes.UpgradeLevel}><img src={images[-2]}/></div>
                {!fireService ? 
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>auto fire service</h1>
                    <h1 className={classes.UpgradeCost}>cost: $6000</h1>
                </div>
                :
                <div className={classes.ShopItemInner}>
                    <h1 className={classes.ShopItemName}>fire service on</h1>
                </div> 
                }
            </div>

            <h1 className={classes.BackHomeButton} onClick={goMenu()}>back to menu</h1>

        </div>
    );
}

export default Shop;
