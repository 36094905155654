import React from 'react';
import classes from './About.module.css';

function About(props) {
    const { setAboutScreen, setHomeScreen } = props;

    const backToMenu = () => {
        setAboutScreen(false);
        setHomeScreen(true);
    };

    return (
        <div className={classes.Container}>
            <div className={classes.TimeAndStars}>
                <div className={classes.TextBox}>
                    <h1>Designed and developed by</h1>
                    <h1>Thomas Godley</h1>
                </div>
                <div className={classes.Stars}>
                </div>
            </div>
            <h1 className={classes.Love}>Keep me going:</h1>
                <a href="https://buymeacoffee.com/thomasgodley" target="_blank" rel="noopener noreferrer"><h1>Buy me a coffee</h1></a>
            <h1 onClick={backToMenu} className={classes.ContinueButton}>back to menu</h1>
        </div>
    );
}

export default About;
