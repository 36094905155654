import React, { useEffect, useState } from 'react';
import classes from './LevelCleared.module.css';
import images from '../../imageMapping';

function LevelCleared(props) {
    const { levelTime, setLevelClearedScreen, levelNumber, highLevelRating, setHighLevelRating, sideNumber, setBarFill, levelFailed, setLevelFailed, startTimer, setLevelComplete, setTowerPlaced, money, setShopScreen } = props;

    const [hasUpdatedLevelRating, setHasUpdatedLevelRating] = useState(false);

    const minutes = Math.floor(levelTime / 60);
    const seconds = levelTime % 60;
    const formattedMinutes = minutes < 10 ? `${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const backToMenu = () => {
        setLevelClearedScreen(false);
        setBarFill(2);
        setTowerPlaced(false);
    };

    const openShop = () => {

        setLevelClearedScreen(false);
        setBarFill(2);
        setTowerPlaced(false);
        setShopScreen(true);
    };


    return (
        <div className={classes.Container}>
            <h1 className={classes.AreaTitle}>season finished</h1>
            <div className={classes.TimeAndStars}>
                <div className={classes.TextBox}>
                    <h1>season lasted:<br/>{formattedMinutes*6} months</h1>
                    <h1>savings: ${money}</h1>
                </div>
            </div>
            <h1 onClick={backToMenu} className={classes.ContinueButton}>back to menu</h1>
            <h1 onClick={openShop} className={classes.ShopButton}>upgrades shop</h1>
        </div>
    );
}

export default LevelCleared;
